import React, {
    useContext,
    useMemo,
    useState,
    createContext,
    useEffect,
    useCallback,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { map, find } from "lodash";

const geocodeUrl = `${process.env.GATSBY_ADMIN_SITE_URL}/wp-json/creelighting/v1/geocode-country`;

const UserLocationContext = createContext();

const positionCallback = async (position, resolve) => {
    const { latitude, longitude } = position.coords;
    try {
        const response = await fetch(
            `${geocodeUrl}?lat=${latitude}&lng=${longitude}`,
        );
        if (response.ok) {
            const data = await response.json();
            resolve(data.toLowerCase());
        } else {
            throw new Error(
                `Failed to fetch user location: ${response.statusText}`,
            );
        }
    } catch (error) {
        console.error(error);
        resolve("us");
    }
};

const errorCallback = (error, reject) => {
    reject(error);
};

const setupListener = async (setLocationAction) => {
    try {
        const country = await new Promise((resolve, reject) => {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            const country = searchParams.get("country");
            if (country) {
                resolve(country.toLowerCase());
            } else if (!window.userLocationWatchId) {
                window.userLocationWatchId =
                    navigator.geolocation.watchPosition(
                        (position) => {
                            positionCallback(position, resolve);
                        },
                        (error) => {
                            errorCallback(error, reject);
                        },
                    );
            }
        });
        setLocationAction(country);
    } catch (error) {
        console.error(error);
        setLocationAction("us");
    }
};

export const UserLocationProvider = ({ children }) => {
    const {
        allWpCountry: { edges },
    } = useStaticQuery(graphql`
        query {
            allWpCountry {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
        }
    `);

    const countries = useMemo(() => map(edges, "node"), [edges]);
    const defaultCountry = useMemo(
        () => find(countries, ["slug", "us"]),
        [countries],
    );

    const [userLocation, setUserLocation] = useState(defaultCountry);

    const setLocationAction = useCallback(
        (code) => {
            setUserLocation(find(countries, ["slug", code]));
        },
        [setUserLocation, countries],
    );

    useEffect(() => {
        setupListener(setLocationAction);

        return () => {
            navigator.geolocation.clearWatch(window.userLocationWatchId);
        };
    }, [setLocationAction]);

    return (
        <UserLocationContext.Provider
            value={{
                countries,
                userLocation,
                setUserLocation: setLocationAction,
            }}
        >
            {children}
        </UserLocationContext.Provider>
    );
};

export const useUserLocation = () => {
    const context = useContext(UserLocationContext);

    if (context === undefined) {
        throw new Error(
            "useUserLocation must be used within a UserLocationProvider",
        );
    }

    return context;
};
